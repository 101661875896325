import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('../components/home'),
    meta: {title: 'OceanView海洋环境监测系统'}
  },
];

/**
 * createWebHashHistory： hash版本的路由，就是路径里面含有#
 * createWebHistory: history的路由，路径里面不含有#
 */
const router = createRouter({
  history: createWebHashHistory(),  // hash路由模式
  // history: createWebHistory(),  // history路由模式
  routes
});

export default router;